import React from "react";
import { Triangle } from "react-loader-spinner";

export default function Preloader(props){
    return(
        <div className={props.className}>
            <div className="holder">
                {/* <div className="pre-item"></div>
                <div className="pre-item"></div> */}
                <Triangle
                visible={true}
                height="80"
                width="80"
                color="#000"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                />
            </div>
            {/* <div className="pre-item"></div> */}
        </div>
    )
}