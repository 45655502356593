import React, { useEffect, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Home(props){
    const [displayedName, setDisplayedName] = useState("Bytegen");
    const fullName = "Isaac A.";
    const initialName = "0xBytegen";
    const {scrollToPortfolio} = props

    useEffect(() => {
        let typingTimeout;
        let currentName = initialName;
        let index = 0;

        const typeName = (name) => {
            if (index < name.length) {
                setDisplayedName(name.substring(0, index + 1));
                index++;
                typingTimeout = setTimeout(() => typeName(name), 100); // Typing speed
            } else {
                clearTimeout(typingTimeout);
                setTimeout(() => {
                    // Change to the other name after typing is done
                    currentName = currentName === initialName ? fullName : initialName;
                    index = 0;
                    typeName(currentName);
                }, 5000); // Pause before changing
            }
        };

        typeName(currentName); // Start typing the initial name

        return () => clearTimeout(typingTimeout); // Cleanup on unmount
    }, []);

    function touchStarted(event){
        const startY = event.touches[0].clientY
        const home = document.getElementById("home")
        home.addEventListener("touchmove", touchMove, {passive: true})
        function touchMove(event){
            const endY = event.changedTouches[0].clientY
            home.addEventListener("touchend", touchEnd)

            function touchEnd(){
                if(endY > parseInt(startY + 200)){
                    // props.swipeUp()
                    return
                } else if(endY < startY - 200) {
                    // props.swipeDown()
                    return
                } else{
                    return
                }
            }
        }
    }

    return(
        <div id="home" className={props.className} onTouchStart={touchStarted}>
            <div className="content">
                <div className="left">
                    <div className="image">
                        <div className="profile"></div>
                        <div className="bg"></div>
                        <div  className="secondary-links">
                            <div>
                                <a href="https://linkedin.com/in/bytegen-dev" target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-linkedin-in" />
                                </a>
                                <a href="https://github.com/bytegen-dev"s target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-github" />
                                </a>
                            </div>
                            <div>
                                <a href="https://twitter.com/bytegen_dev" target="_blank" rel="noreferrer">
                                    {/* <i  className="fa-brands fa-twitter" /> */}
                                    <FaXTwitter />
                                </a>
                                <a href="https://t.me/bytegen_dev" target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-telegram-plane" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right">
                    <h1 className="name">
                        <span className="cryptic-animation">{displayedName}</span>
                        <span style={{ display: "flex" }}>
                            <i className="fa fa-check" />
                            <i className="fa fa-certificate" />
                        </span>
                    </h1>
                    <h3 className="role">{props.isFrench?"développeur react":"Fullstack developer"}</h3>
                    {/* <span className="about">motivated frontend developer with a keen eye for intuitive and responsive web applications that would look great on every device</span> */}
                    <span className="about">{props.isFrench?`Salut, je suis Isaac Adebayo, développeur react et concepteur UI/UX. Je m'intéresse à la création d'applications Web qui sont à la fois belles, réactives et faciles à utiliser.`:`Hi, I'm Isaac - a software developer with expertise in crafting optimized and responsive user experiences 🎨`}</span>
                    <button className="download cv" onClick={scrollToPortfolio} >{props.isFrench?"Portfolio":"Portfolio"}<FaArrowDown /></button>
                </div>

                <div className="primary-links">
                    <a href="https://linkedin.com/in/bytegen-dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-linkedin-in" />
                    </a>
                    <a href="https://github.com/bytegen-dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-github" />
                    </a>
                    <a href="https://twitter.com/bytegen_dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-twitter" />
                    </a>
                </div>
            </div>
        </div>
    )
}
