import React from "react";

export default function Services(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const services = document.getElementById("services")
        services.addEventListener("touchend", touchEnd)
        function touchEnd(event){
            const endY = event.changedTouches[0].clientY
            if(endY > startY + 200){
                // props.swipeUp()
                return
            } else if(endY < startY - 200) {
                // props.swipeDown()
                return
            }
        }
    }


    return(
        <div id="services" className={props.className} onTouchStart={touchStarted} >
            <div className="sides">
                <div className="arrow left">
                    <div className="head" onClick={props.gotoHome}></div>
                </div>
                <div className="arrow right">
                    <div className="head" onClick={props.gotoPortfolio}></div>
                </div>
            </div>
            <div className="heading">
                <h1 className="title">{props.isFrench?"Métier":"Services"}</h1>
            </div>
            <div className="content">
                <div>
                    <h2>
                        {props.isFrench?`Parlons`:`Let's talk`}
                        <br />
                        {props.isFrench?`de votre projet`:`about your project`}
                    </h2>
                    <div className="gotocontact" onClick={props.gotoContact} >{props.isFrench?"Connectez":"Connect"}</div>
                </div>
                <div className="service">
                    <div className="icon" style={{
                        background: "#000",
                        color: "gold",
                    }}>
                        <i className="fa-brands fa-bitcoin" />
                    </div>
                    <h3>{"Web3 Dev."}</h3>
                    {props.isFrench?"Expert en développement dApps, smart contracts et Web3. Je crée des solutions blockchain sécurisées pour NFTs et DeFi.":"Expert in dApps, smart contracts and Web3 development. I build secure blockchain solutions for NFTs and DeFi."}
                </div>
                <div className="service">
                    <div className="icon">
                        <i className="fa-brands fa-html5" />
                    </div>
                    <h3>{props.isFrench?"Développement Web":"Web Development"}</h3>
                    {props.isFrench?"Je développe des applications Web complètes, en couvrant les aspects backend et frontend pour offrir une expérience utilisateur fluide et engageante.":"I develop complete Web applications, covering both backend and frontend aspects to deliver a seamless and engaging user experience."}
                </div>
                <div className="service">
                    <div className="icon" style={{
                        background: "#55c",
                        color: "#fff",
                    }}>
                        <i className="fa-brands fa-react" />
                    </div>
                    <h3>{props.isFrench?"Développement d'applications mobiles":"Mobile App Dev_"}</h3>
                    {props.isFrench?"Création d'applications mobiles puissantes et évolutives pour iOS et Android, utilisant React Native et Flutter.":"Build powerful cross platform mobile applications for ios & android using React Native"}
                </div>
                <div className="service">
                    <div className="icon">
                        <i className="fa-brands fa-figma" />
                    </div>
                    <h3>UI/UX</h3>
                    {props.isFrench?"Je crée des prototypes élégants avec une expérience utilisateur fluide.":"I create elegant prototypes with seamless user experience."}
                </div>
            </div>
        </div>
    )
}