import React from "react";
import { FaXTwitter } from "react-icons/fa6";

export default function Connect(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const contact = document.getElementById("contact")
        contact.addEventListener("touchend", touchEnd)
        function touchEnd(event){
            const endY = event.changedTouches[0].clientY
            if(endY > startY + 200){
                // props.swipeUp()
                return
            } else if(endY < startY - 200) {
                // props.swipeDown()
                return
            }
        }
    }

    const [showfooter, setshowfooter] = React.useState(false)

    function toggleFooter(){
        setshowfooter((prevState)=>{return(!prevState)})
    }

    return(
        <div id="contact" className={props.className} onTouchStart={touchStarted}>
            <div className="content">
                <h1>{props.isFrench?"Connectez":"Connect"} !!</h1>
                <div className="form-holder">
                    <form
                        action="https://formspree.io/f/xjvdoagj"
                        method="POST"
                    >
                    <h3>{props.isFrench?"Laisser un message":"Leave a message"}</h3>
                        <input name="name" type="text" placeholder={props.isFrench?"Entrez votre nom":"Enter your name"} ></input>
                        <input name="email" type="email" placeholder={props.isFrench?"Entrer votre Email":"Enter your email"} ></input>
                        <textarea name="message" placeholder={props.isFrench?"Votre message...":"Your message..."} ></textarea>
                        <button>{props.isFrench?"envoyer le message":"send message"}</button>
                    </form>

                    <div className="fast-lane">
                        <a href="https://linkedin.com/in/bytegen-dev" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-linkedin-in"/> linkedin.com/in/bytegen-dev</a>
                        <a href="https://t.me/bytegen_dev" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-telegram"/> @bytegen_dev</a>
                        <a href="mailto:bytegenlabs@gmail.com" target={"_blank"} rel="noreferrer"><i className="fa fa-envelope"/> bytegenlabs@gmail.com</a>
                    </div>

                </div>
                    <div className={showfooter?"footer show":"footer"}>
                        <div>
                            {props.isFrench?"© 2024 Construit et conçu par Isaac Adebayo":"© 2024 Built and Designed by Isaac Adebayo"}
                            <div cla4ssName="comeup" id="comeup" onClick={toggleFooter}>
                                <i className="fa fa-chevron-up" />
                            </div>
                        </div>
                        <div>
                            <a href="mailto:bytegenlabs@gmail.com" target={"_blank"} rel="noreferrer"><i className="fa fa-envelope" /></a>
                            <a href="https://x.com/bytegen_dev" target={"_blank"} rel="noreferrer"><FaXTwitter /></a>
                            <a href="https://linkedin.com/in/bytegen-dev" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-linkedin-in" /></a>
                        </div>
                        {/* <a className="yhh" href="https://wa.me/+2347035658853" target={"_blank"} rel="noreferrer">{props.isFrench?"contacter le développeur":"contact developer"} <i className="fa fa-arrow-right" /></a> */}
                    </div>
            </div>
        </div>
    )
}
